import { useEffect, useRef, useState } from "react";
import "./styles.css";

import React from "react";
import { useNotifications } from "../../utils/NotificationContext";
import CustomButton from "../Buttons";
import { deleteCookie, getCookie, setCookie } from "../../utils/cookie";
import { isLoggedIn } from "../../utils/API/auth";

const Notifications = () => {
  const { notifications, addNotification } = useNotifications();

  // the State of the device Notification Request
  const [notificationRequestHandled, setNotificationRequestHandled] =
    useState(false);

  // decides if we show the notification request popup
  // only if 1. there is no browser notif permission yet (attempt_request_notif)
  // 2. notifications are not blocked
  // 3. user is logged in
  const is_request_notification =
    getCookie("attempt_request_notification_2") === "true" &&
    getCookie("notifications_blocked_2") !== "true" &&
    isLoggedIn();

  // if the user presses the "Allow" button on the notif popup
  function handleNotificationRequestClick() {
    // we stop attempting to request for 1 day
    setCookie("attempt_request_notification_2", "false", 1);

    // initial request time
    let time_init_request = new Date();

    // this might be the case of Safari PWAbuilder from app store, where
    // we actually ask for notif permissions from the swift code
    if (!("Notification" in window)) {
      setNotificationRequestHandled(true);
      setCookie("notifications_blocked_2", "true", 30);

      // If the device is safari we don't write a notif
      if (
        navigator &&
        navigator.userAgent &&
        !(navigator.userAgent.toLowerCase().indexOf("safari/") > -1)
      ) {
        addNotification("Notifications are not supported by your device.");
      }
      return;
    }

    // If the browser already granted permission, all OK
    if (Notification.permission === "granted") {
      setNotificationRequestHandled(true);
      return;
    }

    // We request permission
    Notification.requestPermission().then(function (permission) {
      console.log("Notification permission: ", permission);
      // If granted, we thank the user for accepting
      if (permission === "granted") {
        setNotificationRequestHandled(true);
        addNotification("Thank you for allowing notifications.", 10);
        deleteCookie("notifications_blocked_2");
      }

      // If denied
      if (permission === "denied") {
        let time_deny_request = new Date();

        // We check if the denial was automatic
        if (time_deny_request.getTime() - time_init_request.getTime() > 1000) {
          // If not (1 second from start to end of request)
          // We notify that the user blocked notifications
          addNotification("You have blocked notifications.", 10);
        } else {
          // Otherwise we notify them to activate notificatins from the app settings
          addNotification(
            "Please enable notifications in the app settings.",
            10
          );
        }
        // We mark that cookies have been blocked
        setNotificationRequestHandled(true);
        setCookie("notifications_blocked_2", "true", 7);
      }
    });
  }

  return (
    <>
      <div className={`absolute notification-wrapper no-bg`}>
        {notifications
          .map((notif, index) => (
            <FloatingNotification
              initial_lifetime={notif.timeStart}
              notification_id={notif.id}
              key={notif.id}
            >
              {notif.text}
            </FloatingNotification>
          ))
          .reverse()}
      </div>

      {is_request_notification && !notificationRequestHandled && (
        <PersistentNotification
          type="request_notification"
          onClick={handleNotificationRequestClick}
        ></PersistentNotification>
      )}
    </>
  );
};

const FloatingNotification = ({
  initial_lifetime,
  notification_id,
  children,
}) => {
  const [timeLeft, setTimeLeft] = useState(initial_lifetime);

  const { removeNotification } = useNotifications();

  const notificationLifetimeInterval = useRef(null);
  useEffect(() => {
    notificationLifetimeInterval.current = setInterval(() => {
      setTimeLeft((t) => t - 0.05);
    }, 50);

    return () => {
      clearInterval(notificationLifetimeInterval.current);
    };
  }, []);

  useEffect(() => {
    if (timeLeft < -1) {
      removeNotification(notification_id);
    }
  }, [removeNotification, timeLeft, notification_id]);

  if (timeLeft < 0 || children === undefined) return <></>;
  return (
    <div className={`z-top-3 ${timeLeft <= 1 ? "disappearing" : ""}`}>
      {children}
      <div
        className="absolute z-top-3"
        style={{
          right:
            "calc(" + (100 - timeLeft * (100 / initial_lifetime)) + "% + 13px)",
        }}
      />

      <div className="dismiss-button no-bg">
        <img
          src="/assets/images/clear_small.png"
          alt="Dismiss button"
          onClick={() => {
            if (timeLeft > 1) setTimeLeft(1);
          }}
        />
      </div>
    </div>
  );
};

const PersistentNotification = ({ type, onClick = null }) => {
  return (
    <div
      className={`persistent-notification ${
        type === "request_notification" ? "request-notification" : ""
      }`}
    >
      <div className="persistent-notification-title">
        <p>
          Allow us to send you notifications,
          <br />
          that will serve as wellbeing
        </p>
        <h1>Reminders</h1>
      </div>

      <img src="/assets/images/polar_bear.svg" alt="Polar bear" />

      <p>
        People who enable notifications are{" "}
        <span className="emphasize">5 times</span> more likely to keep up the
        healthy micro-habbits!
      </p>

      <CustomButton variant="primary" className="w-75 m-auto" onClick={onClick}>
        Allow
      </CustomButton>
    </div>
  );
};

export { Notifications, FloatingNotification };
